/* eslint-disable indent */
import React, { useCallback, useEffect, useState } from 'react';
import { getQueryParamNamed } from '../../SharedLib/Util/QueryParamsUtil';
import { logDebug, logError } from '../../SharedLib/Util/LoggingUtil';
import {
  acceptClassroomInvitation,
  clearClassroomInvitationBrowserStorageMeta,
  getClassroomInvitationDetails,
  getClassroomInvitationDetailsLite,
  IClassroomInvitationDetails,
  IOrganizationGroupInviteLicense,
  setClassroomInvitationBrowserStorageMeta,
} from './ClassroomInvitationUtil';
import { Alert, Button } from 'react-bootstrap';
import styles from './ClassroomInvitation.module.scss';
import { BackgroundSvg as BannerBackgroundSvg } from './Assets/Banner/BackgroundSvg';
import { AbsolventHatSvg as BannerAbsolventHatSvg } from './Assets/Banner/AbsolventHatSvg';
import { OpenBookSvg as ProductOpenBookSvg } from './Assets/Product/OpenBookSvg';
import { AbsolventHatSvg as ProductAbsolventHatSvg } from './Assets/Product/AbsolventHatSvg';
import { ProgressSvg as ProductProgressSvg } from './Assets/Product/ProgressSvg';
import { W3sSvg as ProductW3sSvg } from './Assets/Product/W3sSvg';
import { ClassSvg as ClassMainSvg } from './Assets/Class/ClassSvg';
import { CloseButtonSvg } from './Assets/CloseButtonSvg';
import {
  getUserSessionInfo,
  IUserSessionInfo,
  refreshUserSession,
} from '../../SharedLib/Util/UserSessionUtil';
import Loading from '../Loading/Loading';
import TopNavBar from '../../SharedLib/Component/TopNavBar/TopNavBar';
import { IOperationResult } from '../../SharedLib/Util/InterfaceAndTypeUtil';
import { useHistory } from 'react-router-dom';
import { authUtil } from '../../SharedLib/Util/AuthUtil';
import {
  getPathfinderUrl,
  getPublicProfileUrl,
} from '../../SharedLib/Util/EnvironmentUtil';

export default function ClassroomInvitation() {
  const [loading, setLoading] = useState<boolean>(true);
  const [userSessionSoftLookupDone, setUserSessionSoftLookupDone] =
    useState(false);
  const [userSessionSoftLookupRes, setUserSessionSoftLookupRes] = useState<
    IOperationResult<IUserSessionInfo>
  >({} as IOperationResult<IUserSessionInfo>);
  const [error, setError] = useState<{
    title?: string;
    description: string;
  } | null>(null);
  // const [invitationDetailsLite, setInvitationDetailsLite] = useState<IClassroomInvitationDetailsLite>({} as IClassroomInvitationDetailsLite);
  // const [showBrandedAuthFlow, setShowBrandedAuthFlow] = useState(false);
  const [invitationToken, setInvitationToken] = useState<string | null>(null);
  const [invitation, setInvitation] = useState<IClassroomInvitationDetails>(
    {} as IClassroomInvitationDetails,
  );
  const [buttonActionInProgress, setButtonActionInProgress] =
    useState<boolean>(false);
  const [acceptInvitationTermsConsent, setAcceptInvitationTermsConsent] =
    useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    // mount / unmount
    const htmlElm = document.documentElement;

    htmlElm.classList.add('w3s-classroom-invitation-page');

    return () => {
      htmlElm.classList.remove('w3s-classroom-invitation-page');
    };
  }, []);

  useEffect(() => {
    // step 1
    logDebug('ClassroomInvitation -> init -> styles: ', styles);

    clearClassroomInvitationBrowserStorageMeta();

    const userSessionSoftLookupRes = getUserSessionInfo();
    logDebug(
      'ClassroomInvitation -> init -> userSessionSoftLookupRes: ',
      userSessionSoftLookupRes,
    );

    setUserSessionSoftLookupRes(userSessionSoftLookupRes);

    setUserSessionSoftLookupDone(true);
  }, []);

  useEffect(() => {
    // step 2
    if (!userSessionSoftLookupDone) {
      return;
    }

    const tokenKeyVal = getQueryParamNamed('token');
    logDebug('ClassroomInvitation -> url token key/val: ', tokenKeyVal);

    if (tokenKeyVal?.value) {
      (async () => {
        const invitationDetailsLiteFetchRes =
          await getClassroomInvitationDetailsLite({
            invitationToken: tokenKeyVal.value,
          });
        logDebug(
          'ClassroomInvitation -> invitationDetailsLiteFetchRes: ',
          invitationDetailsLiteFetchRes,
        );

        if (invitationDetailsLiteFetchRes.error.code === '0') {
          // token is valid
          // setInvitationDetailsLite(invitationDetailsLiteFetchRes.data);

          if (invitationDetailsLiteFetchRes.data.invitedMember.canRegister) {
            // no cognito user with email mentioned in the invitation
            if (userSessionSoftLookupRes.error.code === '0') {
              // quit current user session
              await authUtil.logOut({
                context: 'Profile -> ClassroomInvitation',
                reason: {
                  classroomInvitationPushingForSignup: true,
                },
              });

              setUserSessionSoftLookupRes(
                {} as IOperationResult<IUserSessionInfo>,
              );
            }

            // when signup will be done, the user will be redirected to the invitation page
            setClassroomInvitationBrowserStorageMeta({
              url: window.location.href,
              token: tokenKeyVal.value,
              flow: 'signup',
              details: invitationDetailsLiteFetchRes.data,
            });

            // setShowBrandedAuthFlow(true);
            history.push('/signup');
            // setLoading(false);
          } else {
            // invited user already registered in cognito
            if (userSessionSoftLookupRes.error.code === '0') {
              // user session present
              setInvitationToken(tokenKeyVal.value);
            } else {
              // when login will be done, the user will be redirected to the invitation page
              setClassroomInvitationBrowserStorageMeta({
                url: window.location.href,
                token: tokenKeyVal.value,
                flow: 'login',
                details: invitationDetailsLiteFetchRes.data,
              });
              // setShowBrandedAuthFlow(true);
              history.push('/login');
              // setLoading(false);
            }
          }
        } else {
          setError({
            description: `The invitation link is not valid. Reason: ${invitationDetailsLiteFetchRes.error.description} [${invitationDetailsLiteFetchRes.error.code}]`,
          });
          setLoading(false);
        }
      })();
    } else {
      setError({ description: 'The invitation link is not valid' });
      setLoading(false);
    }
  }, [userSessionSoftLookupRes, userSessionSoftLookupDone]);

  useEffect(() => {
    if (!invitationToken) {
      return;
    }

    (async () => {
      try {
        const invitationFetchRes = await getClassroomInvitationDetails({
          invitationToken,
        });

        if (invitationFetchRes.error.code !== '0') {
          logError(
            'ClassroomInvitation -> getClassroomInvitationDetails -> invitationFetchRes: ',
            invitationFetchRes,
          );
          setError({
            description: 'Some error occured while fetching invitation details',
          });
          setLoading(false);
          return;
        }

        const invitation = invitationFetchRes.data;
        logDebug(
          'ClassroomInvitation -> getClassroomInvitationDetails -> res: ',
          invitation,
        );

        if (invitation.invitationStatus === 'FORBIDDEN') {
          logError(
            'ClassroomInvitation -> getClassroomInvitationDetails -> res: ',
            invitation,
          );
          setError({
            description: `You don't have permissions to view this invitation.${
              invitation.invitationError
                ? ' Reason: ' + invitation.invitationError
                : ''
            }`,
          });
          setLoading(false);
          return;
        }

        if (
          !invitation.organizationName ||
          !invitation.groupName ||
          !invitation.invitationStatus
        ) {
          logError(
            'ClassroomInvitation -> getClassroomInvitationDetails -> res: ',
            invitation,
          );
          setError({
            description: 'An error occurred while processing the invitation',
          });
          setLoading(false);
          return;
        }

        // backwards compatibility
        if (!invitation.invitationRole) {
          invitation.invitationRole = 'STUDENT';
        }

        // backwards compatibility
        if (!invitation.groupAdmins && invitation.groupAdmin) {
          invitation.groupAdmins = [
            {
              name: legacyGetClassroomAdminName(invitation.groupAdmin),
              email: legacyGetClassroomAdminEmail(invitation.groupAdmin),
            },
          ];
        }

        setInvitation(invitation);
        setLoading(false);
      } catch (exc: any) {
        logError(
          'ClassroomInvitation -> getClassroomInvitationDetails -> error: ',
          exc,
        );
        setError({
          description: 'An error occurred while processing the invitation',
        });
        setLoading(false);
      }
    })();
  }, [invitationToken]);

  const getInvitationStatusText = (
    invitationStatus: IClassroomInvitationDetails['invitationStatus'],
  ) => {
    switch (invitationStatus) {
      case 'PENDING':
        return 'Pending';
      case 'ACCEPTED':
        return 'Accepted';
      case 'FORBIDDEN':
        return 'Forbidden';
      default:
        return 'Unknown';
    }
  };

  const getNormalizedOrganizationWebsite = (organizationWebsite: string) => {
    if (
      !organizationWebsite.startsWith('https://') &&
      !organizationWebsite.startsWith('https://')
    ) {
      return `https://${organizationWebsite}`;
    }

    return organizationWebsite;
  };

  const legacyGetClassroomAdminEmail = (raw: string) => {
    // raw example: Php Guy [php-guy@gmail.com]
    const emailRegex = /\[([^\]]+)\]/;
    const match = raw.match(emailRegex);

    if (match && match[1].includes('@')) {
      return match[1];
    }

    return '';
  };

  const legacyGetClassroomAdminName = (raw: string) => {
    // raw example: Php Guy [php-guy@gmail.com]
    const nameRegex = /^(.+?)\s*\[/;
    const match = raw.match(nameRegex);

    if (match && match[1].trim().length > 0) {
      return match[1].trim();
    }

    return raw;
  };

  const getProductTypeClassName = (
    license?: IOrganizationGroupInviteLicense,
  ) => {
    if (!license?.productType) {
      return '';
    }

    switch (license.productType) {
      case 'ALT_SUBSCRIPTION':
      case 'SUBSCRIPTION':
        return '-subscription';
      case 'CERT':
        return '-cert';
      case 'CERT_PROGRAM':
        return '-cert-program';
      case 'COURSE':
        return '-course';
      case 'COURSE_PROGRAM':
        return '-course-program';
      case 'TUTORIAL':
      case 'PROGRESS':
        return '-progress';
      default:
        return '';
    }
  };

  const getProductTypeLogo = (license?: IOrganizationGroupInviteLicense) => {
    if (!license?.productType) {
      return <></>;
    }

    switch (license.productType) {
      case 'ALT_SUBSCRIPTION':
      case 'SUBSCRIPTION':
        return <ProductW3sSvg />;
      case 'CERT':
      case 'CERT_PROGRAM':
        return <ProductAbsolventHatSvg />;
      case 'COURSE':
      case 'COURSE_PROGRAM':
        return <ProductOpenBookSvg />;
      case 'TUTORIAL':
      case 'PROGRESS':
        return <ProductProgressSvg />;
      default:
        return <></>;
    }
  };

  const handleContinueToPathfinder = () => {
    window.location.href = getPathfinderUrl();
  };

  const handleAcceptInvitation = useCallback(async () => {
    if (buttonActionInProgress) {
      return;
    }

    if (!invitationToken) {
      logError(
        'ClassroomInvitation -> handleAcceptInvitation -> invitationToken is not set',
      );
      setError({
        description:
          'We are unable to process your request. Please contact support. Error code: CITINS',
      });

      return;
    }

    try {
      setButtonActionInProgress(true);
      const invitationAcceptRes = await acceptClassroomInvitation({
        invitationToken,
      });
      logDebug(
        'ClassroomInvitation -> handleAcceptInvitation -> invitationAcceptRes: ',
        invitationAcceptRes,
      );

      if (invitationAcceptRes.error.code !== '0') {
        logError(
          'ClassroomInvitation -> handleAcceptInvitation -> invitationAcceptRes: ',
          invitationAcceptRes,
        );
        setError({
          description:
            'We are unable to process your request. Please contact support. Error code: CITAE1',
        });
        setButtonActionInProgress(false);
        return;
      }

      const invitation = invitationAcceptRes.data;
      logDebug(
        'ClassroomInvitation -> handleAcceptInvitation -> invitation: ',
        invitation,
      );

      if (invitation.invitationStatus !== 'ACCEPTED') {
        logError(
          'ClassroomInvitation -> handleAcceptInvitation -> invitation: ',
          invitation,
        );
        setError({
          description:
            'We are unable to process your request. Please contact support. Error code: CITAE2',
        });
        setButtonActionInProgress(false);
        return;
      }

      setInvitation(invitation);

      const refreshUserSessionRes = await refreshUserSession(
        'ClassroomInvitation -> updateUserSessionCookies',
      );
      logDebug(
        'ClassroomInvitation -> handleAcceptInvitation -> refreshUserSessionRes: ',
        refreshUserSessionRes,
      );

      if (refreshUserSessionRes.error.code !== '0') {
        logError(
          'ClassroomInvitation -> handleAcceptInvitation -> refreshUserSessionRes: ',
          refreshUserSessionRes,
        );
        setError({
          description:
            'We are unable to process your request. Please contact support. Error code: CITAUSRE',
        });
        setButtonActionInProgress(false);
        return;
      }
    } catch (exc: any) {
      logError('ClassroomInvitation -> handleAcceptInvitation -> error: ', exc);
      setError({
        description:
          'We are unable to process your request. Please contact support. Error code: CITAUE',
      });
    }

    setButtonActionInProgress(false);
  }, [invitationToken, buttonActionInProgress]);

  const handleCancelInvitation = useCallback(() => {
    if (buttonActionInProgress) {
      return;
    }

    clearClassroomInvitationBrowserStorageMeta();

    window.location.href = '/';
  }, [buttonActionInProgress]);

  const handleClose = () => {
    clearClassroomInvitationBrowserStorageMeta();

    window.location.href = '/';
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <TopNavBar
            zIndex={999999}
            userSessionProcessed={userSessionSoftLookupDone} // actual validation / refreshing is done in the inner components
            userSessionMeta={{
              loggedIn: userSessionSoftLookupRes.data.sessionIsPresent!,
              subscriptionPlan: userSessionSoftLookupRes.data.subscriptionPlan,
              featureFlags: {
                noUpsell: userSessionSoftLookupRes.data.noUpsell,
              },
            }}
          />

          <div className="w3s-classrooms-invitation">
            <div className="-banner">
              <div className="-background">
                <BannerBackgroundSvg />
              </div>

              <div className="-info">
                <div className="-logo">
                  <BannerAbsolventHatSvg />
                </div>

                <div className="-title">W3Schools Academy</div>

                <div className="-subtitle">
                  {invitation.invitationRole === 'UNKNOWN' &&
                    'Invitation to become a group member'}
                  {invitation.invitationRole === 'TEACHER' &&
                    'Invitation to become a teacher on Academy'}
                  {invitation.invitationRole === 'STUDENT' &&
                    'Invitation to join the class and get access to products'}
                  {invitation.invitationRole === 'ORGANIZATION_ADMIN' &&
                    `Invitation to become an administrator of "${invitation.organizationName}"`}
                  {invitation.invitationRole === 'ORGANIZATION_MEMBER' &&
                    `Invitation to become a member of "${invitation.organizationName}"`}
                </div>
              </div>
            </div>

            <div className="-body">
              <div className="-inner-wrapper">
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  className="-close-btn"
                >
                  <CloseButtonSvg />
                </Button>

                {error !== null && (
                  <Alert variant="danger" className={styles.alert}>
                    {error.title && (
                      <Alert.Heading>{error.title}</Alert.Heading>
                    )}
                    <p>{error.description}</p>
                  </Alert>
                )}

                {error === null && (
                  <>
                    <div className="-pane -invitation">
                      <div className="-title">Invitation</div>

                      <div className="-description">
                        Welcome to W3Schools Academy!&nbsp;
                        {invitation.invitationRole === 'UNKNOWN' &&
                          'We\'re excited to have you join our team. Please review the details below carefully. Once ready, click \'Accept and join team\' to proceed.'}
                        {invitation.invitationRole === 'TEACHER' &&
                          'We\'re excited to have you join our teaching community. Please review the details below carefully. Once ready, click \'Accept and join the class\' to proceed.'}
                        {invitation.invitationRole === 'STUDENT' &&
                          'Please review the details below carefully. Review your access to the designated products and confirm your acceptance. Once ready, click \'Accept and join the class\' to proceed.'}
                        {invitation.invitationRole === 'ORGANIZATION_ADMIN' &&
                          'We\'re excited to have you as an organization administrator. Please review the details below carefully. Once ready, click \'Accept and join organization\' to proceed.'}
                        {invitation.invitationRole === 'ORGANIZATION_MEMBER' &&
                          'We\'re excited to have you as an organization member. Please review the details below carefully. Once ready, click \'Accept and join organization\' to proceed.'}
                      </div>

                      <div className="-table">
                        <table>
                          <thead>
                            <tr>
                              <th scope="col">STATUS</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {getInvitationStatusText(
                                  invitation.invitationStatus,
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="-pane -organization">
                      <div className="-title">Organization</div>

                      <div className="-table">
                        <table>
                          <thead>
                            <tr>
                              {invitation.organizationLogo && (
                                <th scope="col">LOGO</th>
                              )}
                              <th scope="col">NAME</th>
                              {invitation.organizationWebsite && (
                                <th scope="col">WEBSITE</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {invitation.organizationLogo && (
                                <td className="-organization-logo">
                                  <img
                                    src={`${getPublicProfileUrl()}/${invitation.organizationLogo}`}
                                    alt={invitation.organizationName}
                                  />
                                </td>
                              )}
                              <td>{invitation.organizationName}</td>
                              {invitation.organizationWebsite && (
                                <td>
                                  <a
                                    href={getNormalizedOrganizationWebsite(
                                      invitation.organizationWebsite,
                                    )}
                                    className="-inline-link"
                                    target="_blank"
                                  >
                                    {invitation.organizationWebsite}
                                  </a>
                                </td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    {invitation.invitationRole === 'STUDENT' && (
                      <div className="-pane -classroom">
                        <div className="-title">Classroom</div>

                        <div className="-table">
                          <table>
                            <thead>
                              <tr>
                                <th scope="col">NAME</th>
                                <th scope="col">
                                  {invitation.groupAdmins.length > 1
                                    ? 'ADMINISTRATORS'
                                    : 'ADMINISTRATOR'}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{invitation.groupName}</td>
                                <td>
                                  {invitation.groupAdmins.length === 1 && (
                                    <a
                                      href={`mailto:${invitation.groupAdmins[0].email}`}
                                      className="-inline-link"
                                      target="_blank"
                                    >
                                      {invitation.groupAdmins[0].name}
                                    </a>
                                  )}

                                  {invitation.groupAdmins.length > 1 && (
                                    <ul className="-admin-list">
                                      {invitation.groupAdmins.map((admin) => (
                                        <li key={admin.email}>
                                          <a
                                            href={`mailto:${admin.email}`}
                                            className="-inline-link"
                                            target="_blank"
                                          >
                                            {admin.name}
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}

                    {(invitation.invitationLicenses?.length ?? 0) > 0 && (
                      <div className="-pane -products">
                        <div className="-title">
                          PRODUCTS YOU WILL GET ACCESS TO
                        </div>

                        <div className="-list">
                          {invitation.invitationLicenses!.map((license) => (
                            <span
                              key={license.licenseId}
                              className={`-tag ${getProductTypeClassName(license)}`}
                            >
                              <span className="-logo">
                                {getProductTypeLogo(license)}
                              </span>
                              <span className="-text">
                                {license.productName || license.productId}
                              </span>
                            </span>
                          ))}
                        </div>
                      </div>
                    )}

                    {invitation.invitationRole === 'TEACHER' && (
                      <div className="-pane -classes">
                        <div className="-title">
                          CLASSROOM YOU WILL BE ASSIGNED TO
                        </div>

                        <div className="-list">
                          <span className="-tag">
                            <span className="-logo">
                              <ClassMainSvg />
                            </span>
                            <span className="-text">
                              {invitation.groupName}
                            </span>
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="-pane -take-action">
                      {invitation.invitationStatus === 'PENDING' && (
                        <div className="-accept-invitation">
                          <div className="-terms">
                            <span className="-checkbox-wrapper">
                              <input
                                className="-checkbox"
                                type="checkbox"
                                checked={acceptInvitationTermsConsent}
                                onChange={() =>
                                  setAcceptInvitationTermsConsent(
                                    (prev) => !prev,
                                  )
                                }
                              />
                            </span>
                            <span className="-text">
                              {invitation.invitationRole === 'UNKNOWN' &&
                                'By accepting this invitation, I agree to become a part of the team'}
                              {invitation.invitationRole === 'TEACHER' &&
                                `By accepting this invitation, I agree to be added as a teacher to the classroom "${invitation.groupName}" and acknowledge that I will have administrative rights over the students in this classroom.`}
                              {invitation.invitationRole === 'STUDENT' &&
                                'I am aware that by accepting this invitation, the assigned Administrator will be able to view my progress data for the products listed above.'}
                              {invitation.invitationRole ===
                                'ORGANIZATION_ADMIN' &&
                                `By accepting this invitation, I agree to be added as an administrator to the organization "${invitation.organizationName}" and acknowledge that I will have administrative rights over the students in it's classrooms.`}
                              {invitation.invitationRole ===
                                'ORGANIZATION_MEMBER' &&
                                `By accepting this invitation, I agree to be added as a member to the organization "${invitation.organizationName}".`}
                            </span>
                          </div>

                          <div className="-buttons">
                            <Button
                              variant="success"
                              disabled={
                                buttonActionInProgress ||
                                !acceptInvitationTermsConsent
                              }
                              onClick={handleAcceptInvitation}
                              className="-accept"
                            >
                              Accept and join&nbsp;
                              {invitation.invitationRole === 'UNKNOWN' &&
                                'team'}
                              {invitation.invitationRole === 'TEACHER' &&
                                'the class'}
                              {invitation.invitationRole === 'STUDENT' &&
                                'the class'}
                              {invitation.invitationRole ===
                                'ORGANIZATION_ADMIN' && 'organization'}
                              {invitation.invitationRole ===
                                'ORGANIZATION_MEMBER' && 'organization'}
                            </Button>

                            <Button
                              variant="secondary"
                              disabled={buttonActionInProgress}
                              onClick={handleCancelInvitation}
                              className="-decline"
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      )}

                      {invitation.invitationStatus === 'ACCEPTED' && (
                        <div className="-buttons">
                          <Button
                            variant="success"
                            disabled={buttonActionInProgress}
                            onClick={handleContinueToPathfinder}
                            className="-continue"
                          >
                            Continue
                          </Button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
