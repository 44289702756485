import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './Plan.module.scss';
import { PlanSelectionStateContext } from '../../context/plan-selection-context';
import { QuestionCircle, ChevronDown, InfoCircle } from 'react-bootstrap-icons';
import { Overlay, Tooltip } from 'react-bootstrap';
import { getClassicUrl } from '../../SharedLib/Util/EnvironmentUtil';

interface IFeature {
  text: string | JSX.Element;
  helpText: string;
}

type CardProps = {
  id: string;
  planType: string;
  planName: string;
  price: string;
  period: string;
  description: string;
  features: IFeature[];
  isSelected: boolean;
};

const Card: React.FC<CardProps> = ({
  planType,
  planName,
  price,
  period,
  description,
  features,
  isSelected,
  id,
}) => {
  const { setSelectedPlanId } = useContext(PlanSelectionStateContext);
  const [show, setShow] = useState<boolean[]>(features.map(() => false));
  const [featuresListVisible, setFeaturesListVisible] = useState(true);
  const targets = useRef<(HTMLSpanElement | null)[]>([]);

  const toggleFeaturesListVisibility = () =>
    setFeaturesListVisible(!featuresListVisible);

  const handleSelection = () => {
    if (id !== 'classroom') setSelectedPlanId(id);
  };

  return (
    <div
      className={`${styles.cardContainer} ${isSelected ? styles.selected : ''}  ${planType === 'ACADEMY' ? styles.noScaleNoPointer : ''}`}
      onClick={handleSelection}
    >
      <div className={styles.cardContent}>
        {planType === 'PRO' && (
          <div className={styles.popularRibbon}>Popular</div>
        )}
        <div className={styles.cardHeader}>
          {/*planType !== 'ACADEMY' && (
            <input
              type="checkbox"
              checked={isSelected}
              className={styles.checkbox}
            />
          )*/}
          <h5 className={styles.cardTitle}>{planName}</h5>

          {planType === 'ACADEMY' && (
            <p className={styles.cardPrice}>
              For teachers <br/>
              <a
                target='_blank'
                href={getClassicUrl() + '/academy'}
                className={styles.classroomLink}
              >
                Read more
              </a>
            </p>
          )}
          {planType !== 'ACADEMY' && (
            <p className={styles.cardPrice}>
              {price}
              <span className={styles.cardPeriod}>{period}</span>
            </p>
          )}

          <hr />
        </div>
        <p
          className={styles.cardDescription}
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>

        {featuresListVisible && (
          <ul className={styles.featuresList}>
            {features.map((feature, index) => (
              <li key={index} className={styles.featureItem}>
                <>
                  {feature.text}
                  {feature.helpText && (
                    <>
                      <span 
                        ref={el => targets.current[index] = el} 
                        onMouseEnter={() => setShow(show.map((s, idx) => idx === index ? true : s))}
                        onMouseLeave={() => setShow(show.map((s, idx) => idx === index ? false : s))}
                        style={{ cursor: 'help' }}
                      >
                          &nbsp;<InfoCircle style={{ marginLeft: '5px' }} />
                      </span>
                      <Overlay 
                        target={targets.current[index]} 
                        show={show[index]} 
                        placement="right"
                      >
                        {(props) => (
                          <Tooltip 
                            id={`tooltip-${index}`} 
                            {...props}
                            arrowProps={{ style: { display: 'block' } }}
                          >
                            {feature.helpText}
                          </Tooltip>
                        )}
                      </Overlay>
                    </>
                  )}
                </>
              </li>
            ))}
          </ul>
        )}

        {/*<div onClick={toggleFeaturesListVisibility} style={{ textAlign: 'center', cursor: 'pointer', transition: 'transform 0.3s' }} className={featuresListVisible ? styles.chevronUp : ''}>
          <ChevronDown />
        </div>
                        */}
      </div>

      {planType !== 'ACADEMY' && (
        <button 
          className={`${styles.selectButton} ${isSelected ? styles.selected : ''}`}
          onClick={handleSelection}
          disabled={isSelected}
        >
          {isSelected ? 'Selected' : 'Select'}
        </button>
      )}
    </div>
  );
};

export default Card;
