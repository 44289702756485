import React from 'react';
import styles from './AcademyBanner.module.scss';

const AcademyBanner = () => {
  return (
    <div className={styles.academyBanner}>
        <b>For teachers:</b> &nbsp; Discover W3Schools Academy for your classroom - &nbsp;
        <a
          href="https://w3schools.com/academy/index.php"
          target="new"
        >
          Learn more here!
        </a>
      </div>
  );
};

export default AcademyBanner;
